<template>
  <div style="width: 888px">
    <v-layout v-if="antragfragen_count == 0">
      <v-flex md10 py-1 px-4>
        <br />
        <h3 class="subtitle">Dieser Tarif hat keine Antragsfragen</h3>
        Die {{ tarif.tier_title }} verfügt über eine angenehme Ausnahme. Er hat
        <strong class="green--text">keine Gesundheitsfragen</strong>. Sie können
        direkt mit Schritt 2 von 2 fortfahren.
      </v-flex>
      <v-flex md2></v-flex>
    </v-layout>

    <v-divider pb-10 class="dark"></v-divider>
    <v-layout v-if="antragfragen_count == 1">
      <v-flex md12 py-1 px-4>
        <br />
        <h2 class="subtitle">
          Bitte beantworten Sie jetzt diese Gesundheitsfragen
        </h2>
        <p>
          Für den von Ihnen gewählten Tarif:
          {{ tarif.tier_title }}
        </p>
      </v-flex>
    </v-layout>
    <v-divider class="dark"></v-divider>
    <v-form
      ref="antragform"
      v-show="!antrag_set_submited"
      @submit.prevent="submitAntragStep1(tarif.tarif_id)"
    >
      <div
        v-for="(frage, index) in antragfragen"
        :key="frage.antrag_id"
        :index="index"
      >
        <!-- jA/nein feld -->
        <v-layout v-if="error_frage[frage.antrag_id]">
          <v-flex>
            <h3 class="subtitle red--text">
              {{ error_frage[frage.antrag_id] }} | Fehler-Code:
              {{ error_frage_nr[frage.antrag_id] }}
            </h3>
          </v-flex>
        </v-layout>

        <div v-if="frage.antrag_art == 1">
          <v-container class="pa-4 grey lighten-5">
            <v-layout wrap column-xs-and-down>
              <v-flex md9 class="body-1">
                <span v-if="frage.disabled" class="caption green--text">
                  Diese Antwort basiert auf Ihrer letzten Berechnung |
                  <router-link to="/vergleich/starten">
                    <a>ändern</a>
                  </router-link>
                  <br />
                </span>
                <span v-html="frage.antrag_text"></span>
                <a
                  v-if="frage.antrag_desc != ''"
                  v-on:click="frage.show_antrag_info = !frage.show_antrag_info"
                  >&nbsp;Erklärung anzeigen...</a
                >
              </v-flex>
              <v-flex md3>
                <v-layout v-if="!frage.disabled">
                  <v-radio-group
                    class="pl-4 ma-0"
                    v-model="frage.antrag_input_default"
                    v-on:change="
                      resetUnterfrage(
                        frage.antrag_vue_index_unterfrage,
                        frage.antrag_input_default
                      )
                    "
                    :rules="[checkFrageJaNein(frage)]"
                  >
                    <v-layout row wrap>
                      <v-flex md6>
                        <v-radio key="1" label="Ja" value="ja"></v-radio>
                      </v-flex>

                      <v-flex md6>
                        <v-radio key="0" label="Nein" value="nein"></v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </v-layout>

                <v-layout v-if="frage.disabled">
                  <v-radio-group
                    disabled
                    class="pl-4 ma-0"
                    v-model="frage.antrag_input_default"
                  >
                    <v-layout row wrap>
                      <v-flex md6>
                        <v-radio key="1" label="Ja" value="ja"></v-radio>
                      </v-flex>

                      <v-flex md6>
                        <v-radio key="0" label="Nein" value="nein"></v-radio>
                      </v-flex>
                    </v-layout>
                  </v-radio-group>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-alert
              border="bottom"
              colored-border
              color="blue"
              dismissible
              v-if="
                frage.show_antrag_info == 1 ||
                  (frage.antrag_desc_show_wenn_ja == 1 &&
                    frage.antrag_input_default == 'ja') ||
                  frage.antrag_desc_show_wenn_ja == 2
              "
              >{{ frage.antrag_desc }}</v-alert
            >
          </v-container>
        </div>
        <v-divider class="dark"></v-divider>
        <!-- Anzahl Feld mit Oberfrage -->
        <div
          v-if="
            frage.antrag_art == 2 &&
              frage.antrag_oberfrage > 0 &&
              frage.antrag_vue_index_oberfrage < 99 &&
              antragfragen[frage.antrag_vue_index_oberfrage]
                .antrag_input_default == 'ja'
          "
        >
          <v-container>
            <v-layout wrap row column-xs-and-down align-center>
              <v-flex md1></v-flex>
              <v-flex md9 class="body-1">
                <span v-if="frage.disabled" class="caption green--text">
                  Diese Antwort basiert auf Ihrer letzten Berechnung |
                  <router-link to="/vergleich/starten">
                    <a>ändern</a>
                  </router-link>
                  <br />
                </span>
                <span v-html="frage.antrag_text"></span>
              </v-flex>
              <v-flex md2>
                <span v-if="frage.disabled">
                  <v-text-field
                    disabled
                    v-model="frage.antrag_input_default"
                    label="Anzahl"
                    type="number"
                  ></v-text-field>
                </span>
                <span v-if="!frage.disabled">
                  <v-text-field
                    v-model="frage.antrag_input_default"
                    v-on:change="
                      changeOberfrage(
                        frage.antrag_vue_index_oberfrage,
                        frage.antrag_input_default
                      )
                    "
                    label="Anzahl"
                    type="number"
                    :rules="[
                      checkUnterFrageZahlFeld(
                        frage,
                        antragfragen[frage.antrag_vue_index_oberfrage]
                          .antrag_input_default
                      ),
                    ]"
                  ></v-text-field>
                </span>
              </v-flex>
            </v-layout>
            <v-alert
              border="bottom"
              colored-border
              color="blue"
              dismissible
              v-if="
                frage.show_antrag_info == 1 ||
                  (frage.antrag_desc_show_wenn_ja == 1 &&
                    frage.antrag_input_default == 'ja') ||
                  frage.antrag_desc_show_wenn_ja == 2
              "
              >{{ frage.antrag_desc }}</v-alert
            >
          </v-container>
        </div>
        <v-divider class="dark"></v-divider>
        <!--  Ende Anzahl mit Oberfrage -->

        <!-- Anzahl  Feld ohne Oberfrage -->
        <div v-if="frage.antrag_art == 2 && frage.antrag_oberfrage == 0">
          <v-container>
            <v-layout wrap row column-xs-and-down align-center>
              <v-flex md1></v-flex>
              <v-flex md9 class="body-1">
                <span v-if="frage.disabled" class="caption green--text">
                  Diese Antwort basiert auf Ihrer letzten Berechnung |
                  <router-link to="/vergleich/starten">
                    <a>ändern</a>
                  </router-link>
                  <br />
                </span>
                <span v-html="frage.antrag_text"></span>
                <a
                  v-if="frage.antrag_desc != ''"
                  v-on:click="frage.show_antrag_info = !frage.show_antrag_info"
                  >Erklärung anzeigen...</a
                >
              </v-flex>
              <v-flex md2>
                <span v-if="!frage.disabled">
                  <v-text-field
                    v-model="frage.antrag_input_default"
                    type="number"
                    :rules="[checkFrageZahlFeld(frage)]"
                  ></v-text-field>
                </span>

                <span v-if="frage.disabled">
                  <v-text-field
                    disabled
                    v-model="frage.antrag_input_default"
                    type="number"
                  ></v-text-field>
                </span>
              </v-flex>
            </v-layout>
            <v-alert
              border="bottom"
              colored-border
              color="blue"
              dismissible
              v-if="
                frage.show_antrag_info == 1 ||
                  (frage.antrag_desc_show_wenn_ja == 1 &&
                    frage.antrag_input_default == 'ja') ||
                  frage.antrag_desc_show_wenn_ja == 2
              "
              >{{ frage.antrag_desc }}</v-alert
            >
          </v-container>
        </div>
        <v-divider class="dark"></v-divider>
        <!--  Ende Anzahl mit Oberfrage -->

        <!-- textarea Feld mit Oberfrage -->
        <div
          v-if="
            frage.antrag_art == 3 &&
              frage.antrag_oberfrage > 0 &&
              frage.antrag_vue_index_oberfrage < 99 &&
              antragfragen[frage.antrag_vue_index_oberfrage]
                .antrag_input_default == 'ja'
          "
        >
          <v-container>
            <v-layout wrap row column-xs-and-down align-center>
              <v-flex md1></v-flex>
              <v-flex md10 class="body-1">
                <span v-html="frage.antrag_text"></span>
                <a
                  v-if="frage.antrag_desc != ''"
                  v-on:click="frage.show_antrag_info = !frage.show_antrag_info"
                  >Erklärung anzeigen...</a
                >
              </v-flex>
            </v-layout>
            <v-layout wrap row column-xs-and-down align-center>
              <v-flex md1></v-flex>
              <v-flex md8>
                <v-textarea
                  v-model="frage.antrag_input_default"
                  v-on:change="
                    changeOberfrage(
                      frage.antrag_vue_index_oberfrage,
                      frage.antrag_input_default
                    )
                  "
                  label="Ihre Antwort"
                  type="text"
                  :rules="[
                    checkUnterFrageTextFeld(
                      frage,
                      antragfragen[frage.antrag_vue_index_oberfrage]
                        .antrag_input_default
                    ),
                  ]"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-alert
              border="bottom"
              colored-border
              color="blue"
              dismissible
              v-if="
                frage.show_antrag_info == 1 ||
                  (frage.antrag_desc_show_wenn_ja == 1 &&
                    frage.antrag_input_default == 'ja') ||
                  frage.antrag_desc_show_wenn_ja == 2
              "
              >{{ frage.antrag_desc }}</v-alert
            >
          </v-container>
        </div>
        <v-divider class="dark"></v-divider>
        <!--  Ende text mit Oberfrage -->

        <!-- textarea Feld ohne Oberfrage -->

        <div v-if="frage.antrag_art == 3 && frage.antrag_oberfrage == 0">
          <v-container>
            <v-layout wrap row column-xs-and-down align-center>
              <v-flex md1></v-flex>
              <v-flex md10>
                {{ frage.antrag_text }}
                <a
                  v-if="frage.antrag_desc != ''"
                  v-on:click="frage.show_antrag_info = !frage.show_antrag_info"
                  >Erklärung anzeigen...</a
                >
              </v-flex>
            </v-layout>
            <v-layout wrap row column-xs-and-down align-center>
              <v-flex md1></v-flex>
              <v-flex md8>
                <v-textarea
                  v-model="frage.antrag_input_default"
                  label="Ihre Antwort"
                  type="text"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-alert
              border="bottom"
              colored-border
              color="blue"
              dismissible
              v-if="
                frage.show_antrag_info == 1 ||
                  (frage.antrag_desc_show_wenn_ja == 1 &&
                    frage.antrag_input_default == 'ja') ||
                  frage.antrag_desc_show_wenn_ja == 2
              "
              >{{ frage.antrag_desc }}</v-alert
            >
          </v-container>
        </div>
        <!--  Ende Text ohne Oberfrage -->
      </div>
    </v-form>
  </div>
</template>

<script>
  export default {
    name: "index-antrag",
    components: {},

    data: function() {
      return {
        tarif: false,
        berechnung: false,
        selectfragen: {
          id: this.$route.params.id,
          token: this.$route.params.token,
        },
        type: "berechnung",
        id: this.$route.params.id,
        number: "one",
        antrag_set_submited: false,
        antragfragen_count: {
          type: Number,
          default: null,
        },
        antragfragen: {
          type: Array,
          default: () => [],
        },
        error_frage: Array,
        error_frage_nr: Array,
      };
    },
    created() {
      this.getTarifBerechnung();
    },

    methods: {
      getTarifBerechnung() {
        this.$store.commit("start_loading_full");
        this.$store
          .dispatch("getTarifBerechnung", this.selectfragen)
          .then((resp) => {
            if (resp.data.tarif) {
              this.$store.commit("stop_loading_full");
              this.tarif = resp.data.tarif;
              this.berechnung = resp.data.berechnung;
              this.antragfragen = resp.data.antragfragen;
              this.antragfragen_count = resp.data.antragfragen_count;
            }
          })
          .catch((err) => {
            this.$log.error(err);
          });
      },

      changeOberfrage(oberfrage_index, value) {
        if (value == 0 && oberfrage_index < 99) {
          this.antragfragen[oberfrage_index].antrag_input_default = "nein";
          this.$log.debug("change oberfragefrage back to nein");
        }
      },
      resetUnterfrage(unterfrage_index, value) {
        if (value == "nein" && unterfrage_index < 99) {
          if (this.antragfragen[unterfrage_index].antrag_input_default >= 0) {
            this.antragfragen[unterfrage_index].antrag_input_default = 0;
            this.$log.debug("reset unterfrage zahl");
          } else {
            this.antragfragen[unterfrage_index].antrag_input_default = "";
            this.$log.debug("reset unterfrage text");
          }
        }
      },
      checkFrageJaNein(frage) {
        let showError = false;
        let TextError = "";
        if (frage.antrag_input_default == "") {
          TextError = "Bitte beantworten Sie diese Frage. ";
          showError = true;
        }

        if (
          frage.antrag_input_default == "ja" &&
          frage.antrag_input_ja == "nein"
        ) {
          TextError =
            "Tarif nur abschließbar, wenn Sie diese Frage mit 'Nein' beantworten können";
          showError = true;
        }

        if (
          frage.antrag_input_default == "nein" &&
          frage.antrag_input_ja == "ja"
        ) {
          TextError =
            "Tarif nur abschließbar, wenn Sie diese Frage mit 'Ja' beantworten können";
          showError = true;
        }

        if (showError == true) {
          return TextError;
        } else {
          return true;
        }
      },
      checkFrageZahlFeld(frage) {
        var showError = false;
        var inputnumber = parseInt(frage.antrag_input_default);
        var checknumber = parseInt(frage.antrag_input_max);

        if (checknumber > 0 && inputnumber > checknumber) {
          var TextError = "Nur bis maximal " + checknumber + " abschließbar. ";
          showError = true;
        }
        if (showError == true) {
          return TextError;
        } else {
          return true;
        }
      },
      checkUnterFrageTextFeld(frage, value_oberfrage) {
        var showError = false;
        var TextError = "";
        if (value_oberfrage == "ja" && frage.antrag_input_default == "") {
          TextError = "Bitte beantworten Sie diese Frage. ";
          showError = true;
        }

        if (showError == true) {
          return TextError;
        } else {
          return true;
        }
      }, // ende checkUnterFrageZahlFeld
      checkUnterFrageZahlFeld(frage, value_oberfrage) {
        var showError = false;
        var TextError = "";

        var inputnumber = parseInt(frage.antrag_input_default);
        var checknumber = parseInt(frage.antrag_input_max);

        if (checknumber > 0 && inputnumber > frage.antrag_input_max) {
          TextError = "Nur bis maximal " + checknumber + " abschließbar. ";
          showError = true;
        }
        if (value_oberfrage == "ja" && inputnumber == 0) {
          TextError = "Bitte beantworten Sie diese Frage. ";
          showError = true;
          this.$log.debug("fehler1");
        }

        if (value_oberfrage == "ja" && inputnumber < 0) {
          TextError = "Bitte beantworten Sie diese Frage mit einer Zahl > 0. ";
          showError = true;
        }

        if (showError == true) {
          return TextError;
        } else {
          return true;
        }
      }, // ende checkUnterFrageZahlFeld
      checkStep1Form() {
        let checkPassed = true;

        if (!this.$refs.antragform.validate()) {
          checkPassed = false;
          this.$log.debug("checkStep1Form");
          this.$store.dispatch("insertError", 5);
        }
        return checkPassed;
      },
    },
  };
</script>
